<script>
    import page from 'page';
    import LayoutWrapper from '#components/router/LayoutWrapper.svelte';
    import { activeModule, activeRoute, activeRouteContext } from '#stores/routes/routes.js';
    import { userStore } from '#stores/user/user.js';
    import { upsertAppcuesId } from 'pagebuilder/helpers/appcues.js';

    export let routes;

    const appcuesId = upsertAppcuesId();

    init();

    function init() {
        routes.forEach(route => {
            page(
                route.path,
                (context, next) => (route.redirectTo ? page.redirect(route.redirectTo) : next()),
                (context) => {
                    $activeModule = route.moduleType;
                    $activeRoute = route;
                    $activeRouteContext = context;

                    appcuesId.set(route.pageType);

                    if ($userStore?.id) {
                        window.Appcues.identify(
                            $userStore.id, // unique, required
                            { // custom properties
                                name: $userStore.first_name + ' ' + $userStore.last_name,
                                username: $userStore.username,
                                email: $userStore.email,
                                language_id: $userStore.language,
                                created_at: (new Date($userStore.created_at)).toISOString(),
                            },
                        );

                        window.Appcues.group(
                            $userStore.customer.customers_id, // unique, required
                            { // custom properties
                                name: $userStore.customer.customer_name,
                                created_at: (new Date($userStore.customer.customer_startdate)).toISOString(),
                            });
                    }
                },
            );
            page.start();
        });
    }
</script>

{#if $activeRoute}
    <LayoutWrapper layout={$activeRoute.module}>
        <svelte:component this={$activeRoute.component} />
    </LayoutWrapper>
{/if}
